body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#map {
  width: 100%;
  height: 400px;
  background-color: #eeeeee;
}

.markercluster-map {
  height: 90vh;
}

.text-sm {
  font-size: 0.8em;;
}

.bar {
  margin-left: 10px;
  display: inline-block;
  height: 10px;
  background-color: #D7CCC8;
  border-radius: 5px;
}

.footer-logo {
  width: 200px;
  opacity: 0.5;
}

.min-h-100 {
  min-height: 100%;
}

.slider {
  width: 150px;
  height: 30px;
  margin: auto;
  opacity: 0.7;
}

.track {
  position: relative;
  background: #ddd;
  top: 12px;
  height: 8px;
  border-radius: 4px;
}

.track.track-0 {
  background: #0C6DFD;
}

.thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #0C6DFD;
  border-radius: 100%;
  display: block;
  top: 8px;
  width: 16px;
  height: 16px;
  outline: none;
}
